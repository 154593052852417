import React from 'react';
import { Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';

const RoleInfoForm = props => {
    return (
        <div className="row no-gutters p-1 w-100 flex-grow-1">
            <div className="w-100 h-100 d-flex flex-column">
                <div className="row no-gutters mb-3">
                    <Typography variant="h4" gutterBottom>
                        Datos del rol
                    </Typography>
                </div>
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-12 col-xl-6 p-1">
                        <Field name="roleName" required type="text" variant="outlined" fullWidth label="Nombre" component={TextField} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-xl-6 p-1">
                        <Field name="description" type="text" variant="outlined" fullWidth label="Descripción" component={TextField} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoleInfoForm;
