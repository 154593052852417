import LocalizedStrings from 'react-localization';
export const i18n = new LocalizedStrings({
  es: {
    logout: 'Desconectar',
    loading: 'Cargando',
    unauthorized: 'No tiene permisos para acceder a la aplicación',
    errorUnknown: 'Error desconocido',
    loginError: 'Inicio de sesión fallido',
    loginAgain: 'Inicie sesión de nuevo',
    reload: 'Recargar',
    error: 'Error'
  },
  en: {
    logout: 'Logout',
    loading: 'Loading',
    unauthorized: "You don't have permissions to access the application",
    errorUnknown: 'Unknown error',
    loginError: 'Login unsuccessful',
    loginAgain: 'Login again',
    reload: 'Reload page',
    error: 'Error'
  }
});