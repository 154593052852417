import React from 'react';

import { Typography } from '@material-ui/core';
import UsersWrapper from './users/users-wrapper.component';
import * as AdmTypes from './administration-types';
import GroupsWrapper from './groups/groups-wrapper.component';
import RolesWrapper from './roles/roles-wrapper.component';
import AssignmentWrapper from './assignment/assignment-wrapper.component';

const AdministrationContent = props => {
    const { selectedItem } = props;
    const { value } = selectedItem || {};

    return (
        <div className="h-100 w-100">
            {!value ? (
                <div className="row no-gutters d-flex justify-content-center w-100 h-100 snc-paper">
                    <div className="text-center align-self-center">
                        <div className="row no-gutters">
                            <span>
                                <Typography component="h2">Bienvenid@ al área de Administración</Typography>
                            </span>
                        </div>
                        <div className="row no-gutters">
                            <span>
                                <Typography component="h1">Seleccione una sección desde el menú</Typography>
                            </span>
                        </div>
                    </div>
                </div>
            ) : null}

            {value === AdmTypes.USERS ? <UsersWrapper /> : null}
            {value === AdmTypes.GROUPS ? <GroupsWrapper /> : null}
            {value === AdmTypes.ROLES_PERMISSIONS ? <RolesWrapper /> : null}
            {value === AdmTypes.ASSIGNMENT ? <AssignmentWrapper /> : null}
        </div>
    );
};

export default AdministrationContent;
