import React, { Component } from 'react';
import { fetchUsers } from '../../../redux/administration/users/ActionCreators';
import { connect } from 'react-redux';
import Groups from './groups.component';
import { fetchGroups, putGroup, postGroup, fetchRolesFromGroup, disableGroup } from '../../../redux/administration/groups/ActionCreators';
import { withSnackbar } from 'notistack';
import { ActionProgressReport, ActionProgressReportType } from '@snc/ui';
const mapStateToProps = state => {
    return {
        users: state.users,
        groups: state.groups,
    };
};
const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(fetchUsers()),
    getGroups: () => dispatch(fetchGroups()),
    getGroupRoles: groupId => dispatch(fetchRolesFromGroup(groupId)),
    putGroup: (group, users) => dispatch(putGroup(group, users)),
    postGroup: (group, users) => dispatch(postGroup(group, users)),
    disableGroup: group => dispatch(disableGroup(group)),
});

class GroupsWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroup: null,
        };
    }

    componentDidMount() {
        this.props.getUsers();
        this.props.getGroups();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.groups.isUpdating && !this.props.groups.isUpdating && !this.props.groups.updateErrMess) {
            this.props.enqueueSnackbar('Guardado correctamente', {
                variant: 'success',
            });
        } else if (prevProps.groups.isUpdating && !this.props.groups.isUpdating && this.props.groups.updateErrMess) {
            this.props.enqueueSnackbar(`${this.props.groups.updateErrMess}`, {
                variant: 'error',
            });
        }

        if (prevProps.groups.isCreating && !this.props.groups.isCreating && !this.props.groups.createErrMess) {
            this.props.enqueueSnackbar('Guardado correctamente', {
                variant: 'success',
            });
        } else if (prevProps.groups.isCreating && !this.props.groups.isCreating && this.props.groups.createErrMess) {
            this.props.enqueueSnackbar(`${this.props.groups.createErrMess}`, {
                variant: 'error',
            });
        }
    }
    render() {
        const { users, groups } = this.props;
        const { isCreating, isUpdating } = groups;
        return (
            <React.Fragment>
                <div className="row no-gutters w-100 h-100">
                    <div className="row no-gutters w-100 h-100">
                        {isCreating ? <ActionProgressReport action={ActionProgressReportType.CREATING} /> : null}
                        {isUpdating ? <ActionProgressReport action={ActionProgressReportType.UPDATING} /> : null}
                        <Groups
                            users={users.users}
                            groups={groups.groups.sort((a, b) => a.usersGroupName.localeCompare(b.usersGroupName))}
                            // getGroupUsers={group => this.props.getGroupUsers(group.usersGroupId)}
                            getGroupRoles={group => this.props.getGroupRoles(group.usersGroupId)}
                            groupUsers={this.props.groups.userGroups}
                            putGroup={this.props.putGroup}
                            postGroup={this.props.postGroup}
                            disableGroup={this.props.disableGroup}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(GroupsWrapper));
