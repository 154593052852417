import React, { Component } from 'react';
import GroupsInfoForm from './groups-info-form';
import { Divider, Tooltip, IconButton } from '@material-ui/core';
import GroupsDetailsList from './groups-details-lists';
import CheckIcon from '@material-ui/icons/Check';
import { Form } from 'formik';

const compareLists = (list1, list2) => {
    if (list1.length !== list2.length) return false;
    for (var i = list1.length; i--; ) {
        if (list1[i] !== list2[i]) return false;
    }

    return true;
};

class GroupsDetailsForm extends Component {
    constructor(props) {
        super(props);

        if (props.selectedGroup && !props.newGroup) {
            props.setFieldValue('usersGroupName', props.selectedGroup.usersGroupName ?? '');
            props.setFieldValue('description', props.selectedGroup.description ?? '');
            props.setFieldValue('image', props.selectedGroup.image ?? '');
            props.setFieldValue('imageType', props.selectedGroup.imageType ?? '');
        }

        if ((props.newGroup && !props.selectedGroup) || (!props.newGroup && !props.selectedGroup)) {
            props.resetForm();
        }
    }

    setGroupUsersList(list) {
        if (!compareLists(this.state.groupUsersList, list)) this.setState({ groupUsersList: list });
    }

    render() {
        return (
            <Form className={`w-100 h-100 d-flex flex-column`} noValidate>
                <div className="row no-gutters mb-3">
                    <div className="col-12">
                        <GroupsInfoForm {...this.props} />
                    </div>
                </div>
                <Divider />
                <div className="row no-gutters p-2 mt-3 flex-grow-1 overflow-hidden">
                    <GroupsDetailsList {...this.props} {...this.state} />
                </div>
                <div className="row no-gutters w-100">
                    <div className="w-100">
                        <div className="float-right">
                            <Tooltip title={'Guardar grupo'}>
                                <IconButton type="submit">
                                    <CheckIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default GroupsDetailsForm;
