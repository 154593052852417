import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { i18n } from '../../../i18n';
import RoleDetailsForm from './roles-details-form.component';

const RolesInfoWrapper = props => {
    const { selectedRole, newRole, handleSaveRole } = props;
    return (
        <Formik
            initialValues={{
                roleName: '',
                description: '',
            }}
            validationSchema={Yup.object({
                roleName: Yup.string().required(i18n.obliatorio),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                let roleInfo;
                if (!newRole) {
                    roleInfo = { ...selectedRole };
                }
                roleInfo = {
                    ...roleInfo,
                    ...values,
                };
                handleSaveRole(roleInfo, newRole);
                resetForm();
            }}
            component={componentProps => <RoleDetailsForm {...componentProps} {...props} />}
        />
    );
};

export default RolesInfoWrapper;
