import * as ActionTypes from './ActionTypes';
import { getRequest } from '@snc/api';
import { APP_NAMES } from '../../../utils/app-utils';

import axios from 'axios';

export const onSuccess = response => {
    if (response.status === 200) {
        return response.data;
    } else {
        var error = new Error('Error ' + response.status + ': ' + response.statusText);
        error.response = response;
    }
    throw error;
};

export const onError = (customFields = {}) => error => {
    console.error('API', customFields);
    let message = error.message;
    if (error.response?.status === 401) {
        message = 'Actualmente no tiene permisos en la aplicación';
    }
    throw new Error(message);
};

const fetchApps = config => {
    const getAppsURL = `${config.AUTH.BASE}/${config.AUTH.APPS}`;
    return axios
        .get(getAppsURL)
        .then(onSuccess)
        .catch(onError({ customMessage: 'Error during loading fetchApps' }));
};

export const fetchModules = (appId, config) => {
    const getModulesApp = `${config.AUTH.BASE}/${config.AUTH.APPS}/${appId}`;
    return getRequest(getModulesApp);
};

export const fetchAppsAndVTModulesAction = async (dispatch, getState) => {
    const config = getState().config.config;
    const apps = await fetchApps(config); // catch not necessary because the app cannot work if this fails

    //FIXME esto es una guarrada, mejor en el config o en cualquier lado menos a pelo donde menos se va a esperar que este
    const currentApp = apps.find(app => app.applicationName === APP_NAMES.emiSuiteLauncher);
    if (!currentApp) throw new Error();
    await fetchModulesAction(currentApp.applicationId)(dispatch, getState);
    dispatch(appsLoad(apps));
};

export const fetchModulesAction = appId => async (dispatch, getState) => {
    const config = getState().config.config;
    const app = await fetchModules(appId, config); // catch not necessary because the app cannot work if this fails
    dispatch(modulesAppLoaded(app.modules));
};

const modulesAppLoaded = loadedModules => ({
    type: ActionTypes.LOAD_APP_MODULES,
    payload: loadedModules,
});

const appsLoad = loadedApps => ({
    type: ActionTypes.LOAD_APPS,
    payload: loadedApps,
});

export const selectTreeNode = node => ({
    type: ActionTypes.TREE_NODE_SELECTED,
    payload: node,
});

export const loadTree = tree => ({
    type: ActionTypes.LOAD_TREE,
    payload: tree,
});

export const loadCurrentPermissions = permissions => ({
    type: ActionTypes.LOAD_CURRENT_PERMISSIONS,
    payload: permissions,
});

export const loadExpanded = expanded => ({
    type: ActionTypes.LOAD_EXPANDED,
    payload: expanded,
});
