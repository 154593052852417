import React, { useState, useEffect } from 'react';

import BasicCard from '../../generic/card/basic-card.component';
import { useSelector } from 'react-redux';
import GroupsList from '../groups/groups-list.component';
import RolesList from './roles-list.component';
import UsersList from '../users/users-list';
import { Searcher } from '@snc/ui';
import { filterUsers } from '../../../utils/user-utils';

const Assignment = props => {
    const { users } = useSelector(state => state.users);
    const { roles, assignedRoles } = useSelector(state => state.roles);
    const { groups } = useSelector(state => state.groups);
    const [searchText, setSearchText] = useState('');
    const [groupsSearchText, setGroupsSearchText] = useState('');
    const [roleSearchText, setRoleSearchText] = useState('');
    const { selectedUser, setSelectedUser, selectedGroup, setSelectedGroup, setCheckedRoles } = props;
    const [usersGroups, setUsersGroups] = useState([]);

    useEffect(() => {
        if (selectedUser) {
            let groupsFromUser = [];
            for (const group of selectedUser.groups) {
                let grp = groups.find(g => g.usersGroupId === group.usersGroupId);
                if (grp) groupsFromUser.push({ ...grp, ...group });
            }
            setUsersGroups(groupsFromUser);
        }
    }, [selectedUser, groups]);

    const checkRoles = role => {
        setCheckedRoles({
            granted: true,
            roleId: role.roleId,
            userId: selectedUser.userId,
            usersGroupId: selectedGroup.usersGroupId,
            valid: true,
        });
    };

    return (
        <div className="row no-gutters w-100 h-100 overflow-auto">
            {users ? (
                <div className="col-12 col-md-4 pl-2 pr-2 h-100 ">
                    <BasicCard title="Usuarios">
                        <Searcher value={searchText} onChange={setSearchText} />
                        <div className="w-100 overflow-auto" style={{ height: '90%' }}>
                            <UsersList
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                users={filterUsers(
                                    users.filter(u => u.active),
                                    searchText
                                )}
                            />
                        </div>
                    </BasicCard>
                </div>
            ) : null}
            {selectedUser && selectedUser.groups ? (
                <div className="col-12 col-md-4 pl-2 pr-2 h-100">
                    <BasicCard title="Grupos">
                        <Searcher value={groupsSearchText} onChange={setGroupsSearchText} />
                        <div className="w-100 overflow-auto" style={{ height: '90%' }}>
                            <GroupsList
                                groups={
                                    groupsSearchText && groupsSearchText !== ''
                                        ? usersGroups.filter(
                                              g => g.usersGroupName.toLowerCase().indexOf(groupsSearchText.toLowerCase()) >= 0
                                          )
                                        : usersGroups
                                }
                                selectedGroup={selectedGroup}
                                setSelectedGroup={setSelectedGroup}
                            ></GroupsList>
                        </div>
                    </BasicCard>
                </div>
            ) : null}
            {selectedGroup && roles ? (
                <div className="col-12 col-md-4 pl-2 pr-2 h-100">
                    <BasicCard title="Roles">
                        <Searcher value={roleSearchText} onChange={setRoleSearchText} />
                        <div className="w-100 overflow-auto" style={{ height: '90%' }}>
                            <RolesList
                                roles={
                                    roleSearchText && roleSearchText !== ''
                                        ? roles.filter(r => r.roleName.toLowerCase().indexOf(roleSearchText.toLowerCase()) >= 0 && r.active)
                                        : roles.filter(r => r.active)
                                }
                                checked={assignedRoles}
                                setChecked={checkRoles}
                            />
                        </div>
                    </BasicCard>
                </div>
            ) : null}
        </div>
    );
};

export default Assignment;
