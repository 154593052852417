import React, { Component, useState } from 'react';
import { Dialog, DialogContent, Divider, TextField, InputAdornment } from '@material-ui/core';
import { fetchLdap } from '../../../redux/administration/ldap/ActionCreators';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomDialogTitle from '../modal-title/modal-title.component';
import InfiniteSimpleList from '../list/infinite-simple-list.component';
import SearchIcon from '@material-ui/icons/Search';

const mapStateToProps = state => {
    return {
        ldap: state.ldap,
    };
};
const mapDispatchToProps = dispatch => ({
    fetchLdap: () => dispatch(fetchLdap()),
});

class LdapBrowser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
        };
        this.onClick = this.onClick.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
    }
    componentDidMount() {
        this.props.fetchLdap();
    }
    onClick(ldapUser) {
        this.props.onClose(ldapUser);
    }

    changeSearchText(value) {
        this.setState({ searchText: value });
    }

    applyFilter() {
        return this.props.ldap.ldapUsers.filter(
            ldapUsr => ldapUsr.ldapUserAttributes.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1
        );
    }

    render() {
        const { open } = this.props;
        const items = this.applyFilter();
        return (
            <Dialog onClose={() => this.props.onClose(this.props.selectedValue)} open={open} fullWidth={true} maxWidth={'sm'}>
                <CustomDialogTitle handleCloseDialog={() => this.props.onClose(this.props.selectedValue)}>LDAP Browser</CustomDialogTitle>
                <Divider />
                <DialogContent>
                    <div className="p-2">
                        <div className="row no-gutters w-100">
                            <div className="row no-gutters w-100 pb-2">
                                <div className="col-12">
                                    <TextField
                                        value={this.state.searchText}
                                        onChange={event => this.changeSearchText(event.target.value)}
                                        label="Buscar"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ color: 'grey' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    ></TextField>
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-12">
                                    <LdapLoader items={items} onClick={this.onClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapLdapUsersToItemsList = ldapUsers => {
    return ldapUsers.map(user => {
        return { ...user.ldapUserAttributes };
    });
};
const LdapLoader = props => {
    const { items } = props;
    const [hasNextPage, setHasNextPage] = useState(false);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    // const [items, setItems] = useState(mapLdapUsersToItemsList(props.items));
    const loadNextPage = () => {
        setIsNextPageLoading(true);
        setHasNextPage(items.length < 30);
        setIsNextPageLoading(false);
        // setItems([...items].concat(
        //     new Array(10).fill(true).map((item, index) => ({ id: items.length + index, name: 'Another User' }))
        // ))
    };
    return (
        <InfiniteSimpleList
            hasNextPage={hasNextPage}
            isNextPageLoading={isNextPageLoading}
            items={mapLdapUsersToItemsList(items)}
            loadNextPage={loadNextPage}
            onClick={props.onClick}
        ></InfiniteSimpleList>
    );
};

LdapBrowser.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(LdapBrowser);
