import React from 'react';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
const ModulesList = props => {
    const { modules, selectedModule, setSelectedModule } = props;
    return (
        <>
            <ListSubheader className="mt-3">Módulos</ListSubheader>
            <div className="row no-gutters w-100 overflow-auto" style={{ height: '75%' }}>
                <List className="w-100">
                    {modules.map((module, index) => (
                        <ListItem
                            button
                            dense
                            key={index}
                            onClick={() => setSelectedModule(module)}
                            selected={selectedModule && selectedModule.moduleId === module.moduleId}
                        >
                            <ListItemText primary={module.displayName} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </>
    );
};

export default ModulesList;
