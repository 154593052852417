import * as ActionTypes from './ActionTypes';
import axios from 'axios';

export const fetchLdap = () => (dispatch, getState) => {
    const config = getState().config.config;
    const ldapUsersUrl = `${config.AUTH.BASE}/${config.AUTH.LDAP_USERS}`;

    return axios
        .get(ldapUsersUrl)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(ldapUsers => dispatch(ldapLoad(ldapUsers)))
        .catch(error => {
            dispatch(ldapLoadFailed(error.message));
        });
};

export const ldapLoading = () => ({
    type: ActionTypes.LDAP_LOADING,
});

export const ldapLoad = ldapUsers => ({
    type: ActionTypes.LOAD_LDAP,
    payload: ldapUsers,
});

export const ldapLoadFailed = errMess => ({
    type: ActionTypes.LDAP_LOAD_FAILED,
    payload: errMess,
});
