import React, { useContext } from 'react';
import ModulePermissionProvider, { PermissionContext } from '../permissions/PermissionContext';
import { i18n } from '../../../i18n';
import ErrorMessage from '../errormessage/ErrorMessage';

import PropTypes from 'prop-types';

export const Guard = props => {
    const permissions = useContext(PermissionContext);
    if (!permissions) throw new Error('Cannot use Guard outside PermissionContext');

    const { permViewAccesor, isEditPermissionNeeded, permEditAccesor } = props;

    if (!permissions[permViewAccesor] || (isEditPermissionNeeded && !permissions[permEditAccesor])) {
        return <ErrorMessage errorCode={403} errorMessages={[i18n.unauthorized]} />;
    }

    return props.children;
};

export const GuardModule = props => {
    const { permViewAccesor, isEditPermissionNeeded, permEditAccesor, moduleName, children } = props;

    return (
        <ModulePermissionProvider module={moduleName}>
            <Guard permViewAccesor={permViewAccesor} permEditAccesor={permEditAccesor} isEditPermissionNeeded={isEditPermissionNeeded}>
                {children}
            </Guard>
        </ModulePermissionProvider>
    );
};

GuardModule.propTypes = {
    moduleName: PropTypes.string.isRequired,
    permViewAccesor: PropTypes.string.isRequired,
    permEditAccesor: PropTypes.string,
    isEditPermissionNeeded: PropTypes.bool,
};

GuardModule.defaultProps = {
    permViewAccesor: 'canView',
    permEditAccesor: 'canEdit',
    isEditPermissionNeeded: false,
};
