import * as ActionTypes from './ActionTypes';

import { getRequest, postRequest, putRequest, deleteRequest } from '@snc/api';

export const fetchLauncher = () => (dispatch, getState) => {
    const config = getState().config.config;
    const getLauncherUrl = [config.LINKS.BASE, config.LINKS.LAUNCHERS, config.LAUNCHER_ID].join('/');

    return getRequest(`${getLauncherUrl}?${config.LINKS.CLIENT_ID}=${config.CLIENT_ID}`).then(launcher => dispatch(loadLauncher(launcher)));
};

export const loadLauncher = launcher => ({
    type: ActionTypes.LOAD_LINKS,
    launcher: launcher,
    links: launcher.links,
});

export const postLink = link => (dispatch, getState) => {
    const config = getState().config.config;
    const postLinkUrl = [config.LINKS.BASE, config.LINKS.LAUNCHERS, config.LAUNCHER_ID, config.LINKS.LINKS].join('/');

    return postRequest(postLinkUrl, link).then(() => dispatch(fetchLauncher()));
};

export const linkPosted = () => ({
    type: ActionTypes.LINK_POSTED,
});

export const putLink = link => (dispatch, getState) => {
    const config = getState().config.config;
    const putLinkUrl = [config.LINKS.BASE, config.LINKS.LAUNCHERS, config.LAUNCHER_ID, config.LINKS.LINKS, link.id].join('/');

    return putRequest(putLinkUrl, link).then(() => dispatch(fetchLauncher()));
};

export const linkPutted = () => ({
    type: ActionTypes.LINK_PUTTED,
});

export const deleteLink = link => (dispatch, getState) => {
    const config = getState().config.config;
    const deleteLinkUrl = [config.LINKS.BASE, config.LINKS.LAUNCHERS, config.LAUNCHER_ID, config.LINKS.LINKS, link.id].join('/');

    return deleteRequest(deleteLinkUrl).then(() => dispatch(fetchLauncher()));
};

export const linkDeleted = () => ({
    type: ActionTypes.LINK_DELETED,
});
