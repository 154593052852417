import * as ActionTypes from '../../administration/groups/ActionTypes';

export const Groups = (
    state = {
        isLoading: false,
        errMess: null,
        groups: [],
        userGroups: [],
        roleGroups: [],
        rolesAssigned: [],

        isUpdating: false,
        isCreating: false,
        updateErrMess: null,
        createErrMess: null,
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_GROUPS:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                groups: action.payload,
            };
        case ActionTypes.GROUPS_LOADING:
            return { ...state, isLoading: true, errMess: null, groups: [] };
        case ActionTypes.GROUPS_LOAD_FAILED:
            return {
                ...state,
                isLoading: false,
                errMess: action.payload,
                groups: [],
            };
        case ActionTypes.LOAD_USER_GROUPS:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                userGroups: action.payload,
            };
        case ActionTypes.LOAD_ROLE_GROUPS:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                roleGroups: action.payload,
            };
        case ActionTypes.LOAD_ASSIGNED_ROLES:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                rolesAssigned: action.payload,
            };

        case ActionTypes.GROUPS_UPDATING:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                updateErrMess: null,
                isUpdating: true,
            };
        case ActionTypes.GROUPS_UPDATING_FAILED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                updateErrMess: action.payload,
                isUpdating: false,
            };
        case ActionTypes.GROUPS_UPDATED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                updateErrMess: null,
                isUpdating: false,
            };
        case ActionTypes.GROUPS_CREATING:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                createErrMess: null,
                isCreating: true,
            };
        case ActionTypes.GROUPS_CREATING_FAILED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                createErrMess: action.payload,
                isCreating: false,
            };
        case ActionTypes.GROUPS_CREATED:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                createErrMess: null,
                isCreating: false,
            };
        default:
            return state;
    }
};
