import React, { Component } from 'react';
import { Form } from 'formik';
import { Divider, IconButton } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import RoleInfoForm from './role-info-form.component';
import RolesManagement from './roles-management.component';
class RoleDetailsForm extends Component {
    constructor(props) {
        super(props);
        if (props.selectedRole && !props.newRole) {
            props.setFieldValue('roleName', props.selectedRole.roleName ?? '');
            props.setFieldValue('description', props.selectedRole.description ?? '');
        }
        if (props.newRole) {
            props.resetForm();
        }
    }
    render() {
        return (
            <Form className="w-100 h-100" noValidate>
                <RoleInfoForm {...this.props} />
                <div className="mb-3" />
                <Divider />
                <div className="mb-3" />
                <RolesManagement {...this.props} />
                <div className="row no-gutters mt-4 p-1 w-100">
                    <div className="w-100">
                        <div className="float-right">
                            <IconButton type="submit">
                                <Check />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default RoleDetailsForm;
