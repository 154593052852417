import React from 'react';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ListItem, CircularProgress, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';

const InfiniteSimpleList = props => {
    const { hasNextPage, isNextPageLoading, items, loadNextPage, onClick } = props;
    const itemCount = hasNextPage ? items.length + 1 : items.length;
    const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
    const isItemLoaded = index => !hasNextPage || index < items.length;

    const Row = props => {
        const { index, style, onClick } = props;
        const handleOnClick = item => {
            onClick(item);
        };
        return (
            <React.Fragment>
                {!isItemLoaded(index) ? (
                    <CircularProgress />
                ) : (
                    <ListItem button style={style} key={index} onClick={() => handleOnClick(items[index])}>
                        <ListItemText primary={`${items[index].name}`}></ListItemText>
                    </ListItem>
                )}
            </React.Fragment>
        );
    };
    return (
        <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
            {({ onItemsRendered, ref }) => (
                <List
                    className="List"
                    height={250}
                    itemCount={itemCount}
                    itemSize={35}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    width={530}
                >
                    {rowProps => <Row {...rowProps} onClick={onClick} />}
                </List>
            )}
        </InfiniteLoader>
    );
};

InfiniteSimpleList.propTypes = {
    hasNextPage: PropTypes.bool.isRequired,
    isNextPageLoading: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    loadNextPage: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default InfiniteSimpleList;
