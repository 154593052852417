export const createAsyncMap = (from, useEmpty = false) => {
  return from.reduce((acc, menuItem, index) => {
    if (typeof menuItem.items !== 'function') return acc;
    acc[index] = useEmpty ? {} : menuItem.items;
    return acc;
  }, {});
};
export const getPreselectedMenu = (menuItems, selectedItem) => {
  const preselectedMenuIndex = menuItems.findIndex(menuItem => {
    if (!menuItem.items) return false;
    if (!menuItem.items.find) return false;
    return !!menuItem.items.find(item => item.value && item.value === selectedItem);
  });
  return preselectedMenuIndex > -1 ? preselectedMenuIndex : null;
};