import LocalizedStrings from 'react-localization'; // KEYS MUST BE IN ENGLISH
//! IMPORTANT

export const i18nUI = new LocalizedStrings({
  es: {
    //#region ACTIONS
    actions: 'Acciones',
    search: 'Buscar',
    add: 'Añadir',
    edit: 'Editar',
    filter: 'Filtrar',
    save: 'Guardar',
    saveAndCreate: 'Guardar y añadir nuevo',
    delete: 'Borrar',
    clear: 'Limpiar',
    cancel: 'Cancelar',
    goBack: 'Volver',
    accept: 'Aceptar',
    close: 'Cerrar',
    //#endregion ACTIONS
    // Progress
    loading: 'Cargando',
    creating: 'Creando',
    updating: 'Actualizando',
    saving: 'Guardando',
    deleting: 'Borrando',
    exporting: 'Exportando',
    // End Progress
    contactYourAdmin: 'Contacte con su Administrador',
    somethingWentWrong: 'Algo ha sido mal',
    //Dias de la semana
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    weekDays: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    week: 'Semana',
    mondayShort: 'L',
    tuesdayShort: 'M',
    wednesdayShort: 'X',
    thursdayShort: 'J',
    fridayShort: 'V',
    saturdayShort: 'S',
    sundayShort: 'D',
    // Fin dias de la semana
    // Filter
    applyFilter: 'Filtrar',
    clearFilter: 'Limpiar',
    // End Filter
    // FORMIK
    requiredField: 'Campo Requerido',
    addButton: 'Añadir',
    editButton: 'Editar',
    cancelButton: 'Cancelar',
    numberType: 'El valor debe ser númerico',
    integerType: 'El valor debe ser entero',
    positiveNumberType: 'El valor ha de ser un número positivo',
    stringType: 'El valor ha de ser un texto',
    dateType: 'El valor ha de ser una fecha',
    datetimeType: 'El valor ha de ser una fecha-hora',
    timeType: 'El valor ha de ser una hora',
    durationType: 'El valor debe contener un número de horas y minutos',
    dateRangeError: 'La Fecha Hasta debe ser posterior o igual a la Fecha Desde',
    // FIN FORMIK
    // MISC
    pageNotFound: 'La página no existe',
    // End MISC
    selectAll: 'Seleccionar todos'
  },
  en: {
    //#region ACTIONS
    actions: 'Actions',
    search: 'Search',
    add: 'Add',
    edit: 'Edit',
    filter: 'Filter',
    save: 'Save',
    saveAndCreate: 'Save and create new',
    delete: 'Delete',
    clear: 'Clear',
    cancel: 'Cancel',
    goBack: 'Volver',
    accept: 'Accept',
    close: 'Close',
    //#endregion ACTIONS
    // Progress
    loading: 'Loading',
    creating: 'Creating',
    updating: 'Updating',
    saving: 'Saving',
    deleting: 'Deleting',
    exporting: 'Exporting',
    // End Progress
    contactYourAdmin: 'Get in contact with your administrator',
    somethingWentWrong: 'Something went wrong',
    //Dias de la semana
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    week: 'Week',
    mondayShort: 'M',
    tuesdayShort: 'Tu',
    wednesdayShort: 'W',
    thursdayShort: 'Th',
    fridayShort: 'F',
    saturdayShort: 'Sa',
    sundayShort: 'Su',
    // Fin dias de la semana
    // Filter
    applyFilter: 'Filter',
    clearFilter: 'Clean',
    // End Filter
    // FORMIK
    requiredField: 'Required Field',
    addButton: 'Add',
    editButton: 'Edit',
    cancelButton: 'Cancel',
    numberType: 'Value must be a number',
    integerType: 'Value must be an integer',
    positiveNumberType: 'Value must be a positive number',
    stringType: 'Value must be a text',
    dateType: 'Value must be a date',
    datetimeType: 'Value must be a datetime',
    timeType: 'Value must be a time',
    durationType: 'Value must contain an amount of hours and minutes',
    dateRangeError: 'Until Date must be bigger or equal to From Date',
    // FIN FORMIK
    // MISC
    pageNotFound: 'La página no existe',
    // End MISC
    openAssistent: 'Open assistent',
    changePassword: 'Change password',
    selectAll: 'Select all'
  }
});