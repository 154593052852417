import axios from 'axios';
import { isTokenExpired } from './utils';
import { ERRORS } from './error/errorUtils';
import { i18n } from './i18n';
export const onSuccess = response => {
  if (response.status === 200 || response.status === 201) {
    return response.data;
  } else {
    var error = new Error('Error ' + response.status + ': ' + response.statusText);
    error.response = response;
  }

  throw error;
}; // TODO Decidir si ayuda al renderizado

export const debouncedOnSuccess = response => {
  return onSuccess(response); // return new Promise(resolve => setTimeout(resolve(onSuccess(response)), 500));
};
export const onError = ({
  messages
}) => error => {
  let message = error.message;
  let level = 'error';
  const errorResponse = error.response;

  if (errorResponse) {
    switch (errorResponse.status) {
      case 400:
        switch (errorResponse.headers.errorcode) {
          case '400_01':
            message = i18n.urlParamsDonNotMatchBody;
            break;

          case '400_02':
            message = i18n.invalidData;
            break;

          default:
            break;
          // default:
          //     message = 'Actualmente no tiene permisos en la aplicación';
        }

        break;

      case 401:
        switch (errorResponse.headers.errorcode) {
          case '401_01':
            message = i18n.tokenNotFound;
            break;

          case '401_02':
            message = i18n.tokenInvalid;
            break;

          default:
            message = i18n.unauthorized;
        }

        break;

      case 403:
        message = ERRORS.unauthorized;
        break;

      case 404:
        message = i18n.notFound;
        break;

      case 409:
        switch (errorResponse.headers.errorcode) {
          case '409_01':
            message = i18n.conflict;
            level = 'info';
            break;

          case '409_02':
            message = i18n.activeRelations;
            level = 'info';
            break;

          case '409_03':
            message = i18n.alreadyExists;
            break;

          default:
            throw error;
        }

        break;

      case 422:
        switch (errorResponse.headers.errorcode) {
          case '422_01':
            message = i18n.datesOverlap;
            level = 'info';
            break;

          case '422_02':
            message = i18n.notEnoughTimeAvailable;
            level = 'info';
            break;

          case '422_03':
            message = messages && messages['422_03'] || i18n.resolvedRequestNoModifiable;
            level = 'info';
            break;

          case '422_04':
            message = i18n.expirityDateReached;
            level = 'info';
            break;

          default:
            throw error;
        }

        break;

      default:
        //UNKNOWN y nada, lo mismo... mejor el mensaje original
        //message = ERRORS.uknown;
        throw error;
    }
  }

  if (message === 'Network Error') {
    message = i18n.networkError;
  }

  const customError = new Error(message);
  customError.level = level;
  throw customError;
};
export const getRequest = (url, messages) => {
  return axios.get(url).then(debouncedOnSuccess).catch(onError({
    messages
  }));
};
export const postRequest = (url, data, messages) => {
  return axios.post(url, data).then(debouncedOnSuccess).catch(onError({
    messages
  }));
};
export const putRequest = (url, data, messages) => {
  return axios.put(url, data).then(debouncedOnSuccess).catch(onError({
    messages
  }));
};
export const deleteRequest = (url, messages) => {
  return axios.delete(url).then(debouncedOnSuccess).catch(onError({
    messages
  }));
};
export const assureToken = (getToken, onError, requestTimeout) => {
  axios.interceptors.request.use(config => {
    if (!isTokenValid(getToken())) onError();
    config.timeoutErrorMessage = i18n.timeout;
    const timeout = requestTimeout ? requestTimeout : 10;
    config.timeout = parseInt(timeout) * 1000;
    config.headers['Authorization'] = 'Bearer ' + getToken();
    config.headers['Content-Type'] = 'application/json';
    return config;
  }, error => {
    var errmess = new Error(error.message);
    throw errmess;
  });
};
export const isTokenValid = token => {
  if (!token || isTokenExpired(token)) return false;
  return true;
};