import React from 'react';
import PropTypes from 'prop-types';
SNCTab.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  orientation: PropTypes.string
};
export default function SNCTab({
  children
}) {
  return children;
}