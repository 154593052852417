import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import SncFileUpload from '../../generic/file-upload/snc-file-upload.component';

const imageToB64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

const GroupsInfoForm = props => {
    return (
        <div className="row p-1">
            <div className="col-12 w-100 mb-4">
                <Typography variant="h4" gutterBottom>
                    Datos del grupo
                </Typography>
            </div>
            {props.values.image ? (
                <div className="col-12 col-sm-12 col-md-2 col-xl-2 p-1">
                    <Avatar
                        className="img-responsive fit-image"
                        src={`data:${props.values.imageType};base64,${props.values.image}`}
                        style={{ height: 120, width: 120 }}
                    />
                </div>
            ) : null}
            <div className="col-12 col-sm-12 col-md-2 col-xl-2 p-1">
                <SncFileUpload
                    imagePreviewMaxHeight={100}
                    acceptedFileTypes={['image/*']}
                    maxFileSize={'1MB'}
                    allowMultiple={false}
                    instantUpload
                    labelIdle='Arrastre el archivo o  <span class="filepond--label-action">Explore</span>'
                    server={{
                        process: (fieldName, file, metadata, load) => {
                            imageToB64(file).then(b64 => {
                                props.setFieldValue('image', b64.substr(b64.indexOf(',') + 1));
                                props.setFieldValue('imageType', b64.substring(b64.indexOf(':') + 1, b64.indexOf(';')));
                                load(b64);
                            });
                        },
                        load: (source, load) => {
                            fetch(source)
                                .then(res => {
                                    console.log(res);
                                    return res.blob();
                                })
                                .then(blob => {
                                    console.log(blob);
                                    return load(blob);
                                });
                        },
                    }}
                ></SncFileUpload>
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-xl-4 p-1 align-self-center">
                <Field name="usersGroupName" required type="text" variant="outlined" fullWidth label="Nombre" component={TextField} />
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-xl-4 p-1 align-self-center">
                <Field name="description" required type="text" variant="outlined" fullWidth label="Descripción" component={TextField} />
            </div>
        </div>
    );
};

export default GroupsInfoForm;
