import React, { useState } from 'react';

import AdministrationContent from './AdministrationContent';
import * as AdmTypes from './administration-types';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import './Administration.scss';
import { Accordeon } from '@snc/ui';

const Admninistration = props => {
    const [selectedItem, setSelectedItem] = useState(null);

    const onClick = item => {
        setSelectedItem(item);
    };

    const items = [
        {
            name: 'Acceso',
            icon: <LockOpenIcon />,
            items: [
                {
                    name: 'Usuarios',
                    icon: <PersonIcon />,
                    value: AdmTypes.USERS,
                    onClick: onClick,
                },
                {
                    name: 'Grupos',
                    icon: <GroupIcon />,
                    value: AdmTypes.GROUPS,
                    onClick: onClick,
                },
                {
                    name: 'Roles',
                    icon: <LockIcon />,
                    value: AdmTypes.ROLES_PERMISSIONS,
                    onClick: onClick,
                },
                {
                    name: 'Asignación',
                    icon: <SettingsInputComponentIcon />,
                    value: AdmTypes.ASSIGNMENT,
                    onClick: onClick,
                },
            ],
        },
    ];

    return (
        <div className="row no-gutters h-100 snc-emi-administration-area p-3">
            <div className="col-12 col-xl-auto snc-emi-administration-menu">
                <Accordeon items={items} selectedItem={selectedItem?.value} />
            </div>
            <div className="col-12 col-xl pt-4 pt-xl-0 pl-0 pl-xl-3 h-100">
                <AdministrationContent selectedItem={selectedItem} />
            </div>
        </div>
    );
};

export default Admninistration;
