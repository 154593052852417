import React from 'react';

import { i18n } from '../i18n';

import { AppBar } from '@snc/app-bar';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { Routes } from '../utils/routes';
import { canViewMenuItem } from './generic/permissions/permission-utils';
import { MODULES } from '../utils/module-utils';

const mapStateToProps = state => {
    return {
        loggedUser: state.users.loggedUser,
        config: state.config.config,
        users: state.users,
        apps: state.apps,
        roles: state.roles.roles,
        permissions: state.permissions.permissions,
    };
};

const getMenu = () => [
    {
        value: Routes.administration,
        module: MODULES.Administration.name,
        perm: MODULES.Administration.perms.view,
        tag: <NavLink to={Routes.administration}>{i18n.administration}</NavLink>,
    },
];

function Header(props) {
    const { pathname } = useLocation();
    const { loggedUser, roles, permissions, config } = props;
    const { LOGIN_URL, userApiUrl, logoFile, textFile } = config;

    const { groups } = loggedUser || {};
    const { modules } = props.apps;

    const menuItems = getMenu().filter(canViewMenuItem(groups || [], modules, roles, permissions, config.APP.ID));

    return (
        <AppBar
            menus={menuItems}
            logo={{
                src: `${process.env.PUBLIC_URL}/${logoFile || 'assets/emi-logo.svg'}`,
                src2: `${process.env.PUBLIC_URL}/${textFile || 'assets/launcher-logo.svg'}`,
            }}
            selected={pathname}
            loginUrl={LOGIN_URL || 'https://vt.soincon.es/login'}
            userApiUrl={userApiUrl || 'https://vt.soincon.es/snc-security-ws/users/search-one'}
        />
    );
}

export default connect(mapStateToProps, null)(Header);
