export const MODULES = {
    Administration: {
        name: 'Administration',
        perms: {
            view: 'VIEW',
        },
    },
};

export const getAppModules = (appId, apps, modules) => {
    if (!appId) return [];
    if (!apps) return [];
    if (!modules) return [];
    const app = apps.find(app => app.applicationId === appId) || {};
    const appModules = Object.values(modules).filter(module => module.applicationId === app.applicationID);
    return appModules;
};

export const getModuleByName = (name, modules, applicationId) => {
    if (!modules) return [];

    return Object.values(modules)
        .filter(m => m.applicationId === applicationId)
        .find(({ moduleName }) => moduleName === name);
};
