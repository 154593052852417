import React from 'react';
import { i18n } from '../i18n';

import { ButtonBase } from '@material-ui/core';

import './Home.scss';

function Home(props) {
    // const [favourites, setFavourites] = useState(null);

    const { links } = props;

    // const fetchFavourite = useCallback(() => {
    //     getRequest('http://192.168.2.2:9020/launchers/1/favorites/67')
    //         .then(favourites => setFavourites(favourites))
    //         .catch(() => {
    //             //TODO show error? user may not have defined a favourite
    //         });
    // }, []);

    // useEffect(() => {
    //     fetchFavourite();
    // }, [fetchFavourite]);

    // const favorite = favourites && favourites.link ? favourites.link.linkUrl : undefined;

    // if (favorite && !props.noRedirect) window.location.assign(favorite);

    const apps = [...links].filter(link => !link.isExternal);
    // const externalLinks = [...links].filter(link => link.isExternal);

    return (
        <div className="h-100 limited-width-1200 p-4 d-flex flex-column">
            <div className="row">
                <div className="col-12 text-center">
                    <h3 className="launcher-applications-title">{i18n.applications}</h3>
                </div>
            </div>
            <div className="row d-flex justify-content-center mb-4 flex-grow-1">
                {apps.map(link => (
                    <div key={link.id} className="col-6 col-sm-3 col-lg-2 col-xxxl-3 pt-4">
                        <AppContent link={link} />
                    </div>
                ))}
            </div>

            {/* {externalLinks && externalLinks.length > 0 && (
                <div className="w-100">
                    <div className="row pb-2">
                        <div className="offset-2 col-8">
                            <Divider />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 pt-4 text-center">
                            <h5 className="launcher-external-title">{i18n.external}</h5>
                        </div>
                    </div>

                    <div className="row pb-4 external-links-container justify-content-center flex-grow-1 overflow-auto">
                        {externalLinks.map(link => {
                            return (
                                <div key={link.id} className="col-3 col-md-2 pt-4">
                                    <AppContent link={link} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )} */}
        </div>
    );
}

function AppContent(props) {
    const { linkUrl, isExternal, image, name } = props.link;

    return (
        <ButtonBase style={{ borderRadius: '10%' }}>
            <a href={linkUrl} target={isExternal ? '_blank' : ''}>
                <div className="emi-launcher-app-entry">
                    <svg width="100%" height="100%" viewBox="0 0 900 900" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <rect id="rounded-image-rect" x="0" y="0" width="900" height="900" rx="60" ry="60" />
                            <clipPath id="rounded-image-clip">
                                <use href="#rounded-image-rect" />
                            </clipPath>
                        </defs>

                        <image
                            href={image ? `data:image/${image.imageType};base64,${image.content}` : ''}
                            x="80"
                            y="30"
                            rx="50"
                            ry="50"
                            width="740"
                            height="740"
                            preserveAspectRatio="xMidYMid slice"
                            clipPath="url(#rounded-image-clip)"
                            style={{ boxShadow: '0 0 10px', opacity: 0.86, padding: '20px' }}
                        />

                        {name && (
                            <>
                                <rect
                                    id="menu-entry-name-rect"
                                    x="0"
                                    y="740"
                                    rx="60"
                                    ry="60"
                                    width="900"
                                    height="160"
                                    style={{ fill: 'white', opacity: 1 }}
                                />

                                <text
                                    x="450"
                                    y="840"
                                    width="900"
                                    height="125"
                                    textAnchor="middle"
                                    fontFamily="Roboto"
                                    style={{
                                        fontSize: '80px',
                                        textTransform: 'uppercase',
                                        fontWeight: 500,
                                    }}
                                >
                                    {name}
                                </text>
                            </>
                        )}
                    </svg>
                </div>
            </a>
        </ButtonBase>
    );
}

export default Home;
