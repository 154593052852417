import * as ActionTypes from './ActionTypes';

export const fetchConfig = () => dispatch =>
    fetch(`${process.env.PUBLIC_URL}/config.json`)
        .then(
            response => {
                if (response.ok) {
                    return response;
                } else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.json())
        .then(appConfig => {
            dispatch(configLoad(appConfig));
            return appConfig;
        })
        .catch(err => dispatch(configLoadFailed(err.message)));

export const configLoading = () => ({
    type: ActionTypes.LOAD_CONFIG_LOADING,
});

export const configLoad = appConfig => ({
    type: ActionTypes.LOAD_CONFIG,
    payload: appConfig,
});

export const configLoadFailed = errMess => ({
    type: ActionTypes.LOAD_CONFIG_FAILED,
    payload: errMess,
});
