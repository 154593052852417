import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography } from '@material-ui/core';
import GroupUsersCount from './group-users-count.component';

const GroupsList = props => {
    const { groups, setSelectedGroup, selectedGroup, showCount } = props;
    return (
        <React.Fragment>
            {groups ? (
                <List>
                    {groups.map((group, index) => {
                        return (
                            <ListItem
                                selected={selectedGroup && group.usersGroupId === selectedGroup.usersGroupId}
                                onClick={() => setSelectedGroup(group)}
                                key={index}
                                dense
                                button
                                disabled={!group.active}
                            >
                                <ListItemAvatar>
                                    {group.image && group.imageType ? (
                                        <Avatar src={`data:${group.imageType};base64,${group.image}`} />
                                    ) : (
                                        <Avatar src={`${process.env.PUBLIC_URL}/assets/GROUP.svg`} />
                                    )}
                                </ListItemAvatar>
                                <ListItemText
                                    // primary={`${group.usersGroupName ?? ''}  (${group.users.length})`}
                                    primary={
                                        <div className="row no-gutters align-items-center">
                                            <Typography className="mr-2"> {group.usersGroupName} </Typography>
                                            {showCount ? <GroupUsersCount count={group.users.length} /> : null}
                                        </div>
                                    }
                                    secondary={group.description}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}
        </React.Fragment>
    );
};

export default GroupsList;
