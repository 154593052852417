import * as ActionTypes from './ActionTypes';
import axios from 'axios';

export const fetchGroups = () => (dispatch, getState) => {
    dispatch(groupsLoading());

    const config = getState().config.config;
    const getGroupsURL = `${config.AUTH.BASE}/${config.AUTH.GROUPS}`;

    return axios
        .get(getGroupsURL)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(groups => dispatch(groupsLoad(groups.sort((a, b) => a.usersGroupName.localeCompare(b.usersGroupName)))))
        .catch(error => dispatch(groupsLoadFailed(error.message)));
};

export const fetchUsersFromGroup = groupID => (dispatch, getState) => {
    const config = getState().config.config;
    const getGroupsURL = `${config.AUTH.BASE}/${config.AUTH.GROUPS}/${groupID}/${config.AUTH.USERS}`;

    return axios
        .get(getGroupsURL)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(groups => dispatch(groupsUserLoad(groups)))
        .catch(error => dispatch(groupsLoadFailed(error.message)));
};

export const fetchRolesFromGroup = groupID => (dispatch, getState) => {
    const config = getState().config.config;
    const getGroupsURL = `${config.AUTH.BASE}/${config.AUTH.GROUPS}/${groupID}/${config.AUTH.ROLES}`;

    return axios
        .get(getGroupsURL)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(groups => dispatch(groupsRoleLoad(groups)))
        .catch(error => dispatch(groupsLoadFailed(error.message)));
};

export const putGroup = (group, users) => (dispatch, getState) => {
    if (group !== null) {
        dispatch(groupsUpdating());
        const config = getState().config.config;
        const putGroupURL = `${config.AUTH.BASE}/${config.AUTH.GROUPS}/${group.usersGroupId}`;

        return axios
            .put(putGroupURL, group)
            .then(() => dispatch(groupUpdated()))
            .then(() => dispatch(fetchGroups()))
            .catch(error => dispatch(groupsUpdatingFailed(error.message)));
    }
};

export const postGroup = (group, users) => (dispatch, getState) => {
    if (group !== null) {
        dispatch(groupsCreating());
        const config = getState().config.config;
        const postGroupURL = `${config.AUTH.BASE}/${config.AUTH.GROUPS}`;

        return axios
            .post(postGroupURL, { ...group, active: true, versionLock: 0 })
            .then(
                response => {
                    if (response.status === 200) {
                        return response;
                    } else {
                        var error = new Error('Error ' + response.status + ': ' + response.statusText);
                        error.response = response;
                        throw error;
                    }
                },
                error => {
                    var errmess = new Error(error.message);
                    throw errmess;
                }
            )
            .then(response => response.data)
            .then(() => dispatch(groupsCreated()))
            .then(() => dispatch(fetchGroups()))
            .catch(error => dispatch(groupsCreatingFailed(error.message)));
    }
};

export const disableGroup = group => dispatch => {
    dispatch(putGroup({ ...group, active: false }));
};

export const saveAssignment = (user, group, roles) => (dispatch, getState) => {
    return true;
};

export const getAssignment = (userId, groupId) => (dispatch, getState) => {
    if (userId !== null && groupId !== null) {
        const config = getState().config.config;
        const getAssignmentUrl = `${config.AUTH.BASE}/${config.AUTH.GROUPS}/${groupId}/${config.AUTH.USERS}/${userId}/${config.AUTH.ROLES}`;
        return axios
            .get(getAssignmentUrl)
            .then(
                response => {
                    if (response.status === 200) return response;
                    else {
                        var error = new Error('Error ' + response.status + ': ' + response.statusText);
                        error.response = response;
                        throw error;
                    }
                },
                error => {
                    var errmess = new Error(error.message);
                    throw errmess;
                }
            )
            .then(response => response.data)
            .then(res => dispatch(rolesAssignedLoad(res)));
    }
};

export const rolesAssignedLoad = roles => ({
    type: ActionTypes.LOAD_ASSIGNED_ROLES,
    payload: roles,
});

export const groupsLoading = () => ({
    type: ActionTypes.GROUPS_LOADING,
});

export const groupsUserLoad = loadedGroups => ({
    type: ActionTypes.LOAD_USER_GROUPS,
    payload: loadedGroups,
});

export const groupsRoleLoad = loadedGroups => ({
    type: ActionTypes.LOAD_ROLE_GROUPS,
    payload: loadedGroups,
});

export const groupsLoad = loadedGroups => ({
    type: ActionTypes.LOAD_GROUPS,
    payload: loadedGroups,
});

export const groupsLoadFailed = errMess => ({
    type: ActionTypes.GROUPS_LOAD_FAILED,
    payload: errMess,
});

export const groupsUpdating = () => ({
    type: ActionTypes.GROUPS_UPDATING,
});

export const groupsUpdatingFailed = errMess => ({
    type: ActionTypes.GROUPS_UPDATING_FAILED,
    payload: errMess,
});

export const groupUpdated = () => ({
    type: ActionTypes.GROUPS_UPDATED,
});

export const groupsCreating = () => ({
    type: ActionTypes.GROUPS_CREATING,
});

export const groupsCreatingFailed = errMess => ({
    type: ActionTypes.GROUPS_CREATING_FAILED,
    payload: errMess,
});

export const groupsCreated = () => ({
    type: ActionTypes.GROUPS_CREATED,
});
