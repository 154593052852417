export const formatCompleteUserName = (user, bySurname) => {
    let first, second, last;
    if (bySurname) {
        first = user.firstSurname ?? '';
        second = user.secondSurname ?? '';
        last = user.name ?? '';
    } else {
        first = user.name ?? '';
        second = user.firstSurname ?? '';
        last = user.secondSurname ?? '';
    }
    if (first !== '') first += ' ';
    if (second !== '') second += ' ';
    if (last !== '') last += ' ';

    return `${first}${second}${last}`;
};

export const createName = (firstName, secondName1, secondName2) => {
    return [firstName, secondName1, secondName2].join(' ');
};

export const filterUsers = (source, text) => {
    let result = source;
    if (text && text !== '') {
        result = source.filter(u => {
            const byName = formatCompleteUserName(u)
                .toLocaleLowerCase()
                .includes(text.toLocaleLowerCase());
            const byUserName = u.username.indexOf(text) >= 0;
            return byName | byUserName;
        });
    }
    return result;
};
