export const LOAD_USERS = 'LOAD_USERS';
export const USERS_LOADING = 'USERS_LOADING';
export const USERS_LOAD_FAILED = 'USERS_LOAD_FAILED';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';

export const LOAD_USER_GROUPS = 'LOAD_USER_GROUPS';

export const USERS_UPDATING = 'USERS_UPDATING';
export const USERS_UPDATING_FAILED = 'USERS_UPDATING_FAILED';
export const USERS_UPDATED = 'USERS_UPDATED';

export const USERS_CREATING = 'USERS_CREATING';
export const USERS_CREATING_FAILED = 'USERS_CREATING_FAILED';
export const USERS_CREATED = 'USERS_CREATED';
