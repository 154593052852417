import { CustomError } from './error';
export let user = null;
export let userError = null;

let resolveGetUser = () => {};

let rejectGetUser = () => {}; //TODO!!!! FIXME!!!!! must use @snc/api as the rest of packages


export const getUser = () => {
  if (user) {
    return Promise.resolve(user);
  }

  if (userError) {
    return Promise.reject(userError);
  }

  return new Promise((resolve, reject) => {
    resolveGetUser = resolve;
    rejectGetUser = reject;
  });
};
export const fetchLoggedUser = async (url, token, username) => {
  if (!token) {
    // It should be 401, but this code is returned when 403
    // We should swap these two on BE
    throw new CustomError(4011);
  }

  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      Accept: 'application/json, text/plain, */*'
    },
    mode: 'cors',
    body: JSON.stringify({
      content: {
        username
      }
    })
  };

  try {
    const response = await fetch(url, options);

    if (response.status > 400) {
      throw new CustomError(response.status);
    }

    user = await response.json();
    resolveGetUser(user);
    return user;
  } catch (e) {
    userError = e;

    if (e.message === 'Failed to fetch') {
      // Probably incorrect token
      userError = new CustomError(4011);
    }

    rejectGetUser(userError);
    throw userError;
  }
};