import * as ActionTypes from './ActionTypes';
import axios from 'axios';

export const fetchRoles = () => (dispatch, getState) => {
    dispatch(rolesLoading);

    const config = getState().config.config;
    const getRolesUrl = `${config.AUTH.BASE}/${config.AUTH.ROLES}`;

    return axios
        .get(getRolesUrl)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(roles => dispatch(rolesLoad(roles.sort((a, b) => a.roleName.localeCompare(b.roleName)))))
        .catch(error => dispatch(rolesLoadFailed(error.message)));
};
export const fetchRolesByGroupUser = (idUser, idGroup) => (dispatch, getState) => {
    const config = getState().config.config;
    const getRolesUrl = `${config.AUTH.BASE}/${config.AUTH.GROUPS}/${idGroup}/${config.AUTH.USERS}/${idUser}/${config.AUTH.ROLES}`;
    return axios
        .get(getRolesUrl)
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(roles => dispatch(rolesUserGroupLoad(roles)))
        .catch(error => dispatch(rolesLoadFailed(error.message)));
};

export const linkPermissionsToRole = role => (dispatch, getState) => {
    const roleId = role.roleId;
    const requests = [];
    const permissions = getState().permissions.checkedPermissions;

    const config = getState().config.config;
    const rolesUrl = `${config.AUTH.BASE}/${config.AUTH.ROLES}/${roleId}/${config.AUTH.PERMISSIONS}`;

    permissions.forEach(perm => requests.push(axios.post(rolesUrl, { ...perm, roleId: roleId })));
    return axios.all(permissions);
};

export const handleSaveRole = role => (dispatch, getState) => {
    debugger;
    const config = getState().config.config;
    const rolesUrl = `${config.AUTH.BASE}/${config.AUTH.ROLES}`;

    if (role) {
        let newRole = {
            ...role,
            permissions: getState().permissions.checkedPermissions,
        };
        newRole.permissions = newRole.permissions.map(p => ({ roleId: newRole.roleId, permissionId: p.permissionId }));
        if (role.versionLock !== undefined) {
            newRole = { ...role, ...newRole };
            dispatch(rolesUpdating());
            return axios
                .put(`${rolesUrl}/${newRole.roleId}`, { ...newRole, childRolesIds: [] })
                .then(
                    response => {
                        if (response.status === 200) return response;
                        else {
                            var error = new Error('Error ' + response.status + ': ' + response.statusText);
                            error.response = response;
                            throw error;
                        }
                    },
                    error => {
                        var errmess = new Error(error.message);
                        throw errmess;
                    }
                )
                .then(response => response.data)
                .then(() => dispatch(fetchRoles()))
                .then(() => dispatch(rolesUpdated()))
                .catch(error => dispatch(rolesUpdatingFailed(error.message)));
        } else {
            dispatch(rolesCreating());
            // TODO: SON ROLES
            return axios
                .post(rolesUrl, { ...newRole, versionLock: 0, active: true, sonRoles: [] })
                .then(
                    response => {
                        if (response.status === 200) return response;
                        else {
                            var error = new Error('Error ' + response.status + ': ' + response.statusText);
                            error.response = response;
                            throw error;
                        }
                    },
                    error => {
                        var errmess = new Error(error.message);
                        throw errmess;
                    }
                )
                .then(response => response.data)
                .then(() =>
                    dispatch(fetchRoles())
                        .then(() => dispatch(rolesCreated()))
                        .catch(error => dispatch(rolesCreatingFailed(error.message)))
                );
        }
    }
};

export const disableRole = role => (dispatch, getState) => {
    const config = getState().config.config;
    const rolesUrl = `${config.AUTH.BASE}/${config.AUTH.ROLES}`;
    return axios
        .put(`${rolesUrl}/${role.roleId}`, {
            ...role,
            childRolesIds: [],
            active: false,
        })
        .then(
            response => {
                if (response.status === 200) return response;
                else {
                    var error = new Error('Error ' + response.status + ': ' + response.statusText);
                    error.response = response;
                    throw error;
                }
            },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            }
        )
        .then(response => response.data)
        .then(() => dispatch(fetchRoles()));
};

export const setCheckedRoles = checkedRoles => ({
    type: ActionTypes.SET_CHECKED_ROLES,
    payload: checkedRoles,
});

export const setAssignedRoles = assignedRoles => ({
    type: ActionTypes.SET_ASSIGNED_ROLES,
    payload: assignedRoles,
});

export const rolesLoading = () => ({
    type: ActionTypes.ROLES_LOADING,
});
export const rolesUserGroupLoad = loadedRoles => ({
    type: ActionTypes.LOAD_USER_ROLES_GROUP,
    payload: loadedRoles,
});
export const rolesLoad = loadedRoles => ({
    type: ActionTypes.LOAD_ROLES,
    payload: loadedRoles,
});

export const rolesLoadFailed = errMess => ({
    type: ActionTypes.ROLES_LOAD_FAILED,
    payload: errMess,
});

export const updateNewRoleName = roleName => ({
    type: ActionTypes.UPDATE_NEW_ROLE_NAME,
    payload: roleName,
});
export const updateNewRoleDescription = roleDescription => ({
    type: ActionTypes.UPDATE_NEW_ROLE_DESCRIPTION,
    payload: roleDescription,
});

export const rolesUpdating = () => ({
    type: ActionTypes.ROLES_UPDATING,
});

export const rolesUpdatingFailed = errMess => ({
    type: ActionTypes.ROLES_UPDATING_FAILED,
    payload: errMess,
});

export const rolesUpdated = () => ({
    type: ActionTypes.ROLES_UPDATED,
});

export const rolesCreating = () => ({
    type: ActionTypes.ROLES_CREATING,
});

export const rolesCreatingFailed = errMess => ({
    type: ActionTypes.ROLES_CREATING_FAILED,
    payload: errMess,
});

export const rolesCreated = () => ({
    type: ActionTypes.ROLES_CREATED,
});
