import moment from 'moment';
export const getTokenInfo = token => {
  if (!token) {
    return {};
  }

  const tokenParts = token.split('.');
  const encodedPayload = tokenParts[1];
  const rawPayload = atob(encodedPayload);
  const parsed = JSON.parse(rawPayload);
  return {
    expire: parsed.exp,
    username: parsed.sub
  };
};
export const isTokenExpired = token => {
  const {
    expire
  } = getTokenInfo(token);

  if (!expire) {
    return true;
  }

  return moment.unix(expire) < moment();
};