import React from 'react';
import ErrorMessage from '../generic/errormessage/ErrorMessage';
import { i18n } from '../../i18n';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    render() {
        const { error } = this.state;
        if (!error) {
            return this.props.children;
        }
        return (
            <div className="d-flex justify-content-center h-100 align-items-center">
                <ErrorMessage errorCode={error.code} errorMessages={[i18n.somethingWentWrong, error.message]} />
            </div>
        );
    }
}
