import * as ActionTypes from '../../administration/ldap/ActionTypes';

export const LDAP = (
    state = {
        isLoading: true,
        errMess: null,
        ldapUsers: [],
    },
    action
) => {
    switch (action.type) {
        case ActionTypes.LOAD_LDAP:
            return {
                ...state,
                isLoading: false,
                errMess: null,
                ldapUsers: action.payload,
            };
        case ActionTypes.LDAP_LOADING:
            return { ...state, isLoading: true, errMess: null, ldapUsers: [] };
        case ActionTypes.LDAP_LOAD_FAILED:
            return {
                ...state,
                isLoading: false,
                errMess: action.payload,
                ldapUsers: [],
            };
        default:
            return state;
    }
};
