import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Typography, Avatar } from '@material-ui/core';
import SncFileUpload from '../../generic/file-upload/snc-file-upload.component';

const imageToB64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

const UserInfoForm = props => {
    return (
        <React.Fragment>
            <div className="row no-gutters p-1 w-100 flex-grow-1">
                <div className="w-100 h-100 d-flex flex-column">
                    <div className="row no-gutters mb-3">
                        <Typography variant="h4" gutterBottom>
                            Datos personales
                        </Typography>
                    </div>
                </div>
                <div className="row no-gutters flex-grow-1">
                    <div className="row no-gutters w-100 p-1 align-items-center align-self-center" style={{ height: '80%' }}>
                        <div className="col-12 col-sm-12 col-md-12 col-xl-4 h-100 w-100 p-2">
                            <div className="h-100 w-100" style={{ height: '10vh' }}>
                                {props.values.image ? (
                                    <div className="row no-gutters mb-3">
                                        <div className="h-100 col d-flex"></div>
                                        <div className="col-auto">
                                            <Avatar
                                                className="img-responsive fit-image"
                                                src={`data:${props.values.imageType};base64,${props.values.image}`}
                                                style={{ height: 120, width: 120 }}
                                            />
                                        </div>
                                        <div className="col" />
                                    </div>
                                ) : null}
                                <SncFileUpload
                                    imagePreviewMaxHeight={100}
                                    className="w-100 h-100"
                                    acceptedFileTypes={['image/*']}
                                    maxFileSize={'1MB'}
                                    allowMultiple={false}
                                    instantUpload
                                    labelIdle='Arrastre el archivo o  <span class="filepond--label-action">Explore</span>'
                                    server={{
                                        process: (fieldName, file, metadata, load) => {
                                            imageToB64(file).then(b64 => {
                                                props.setFieldValue('image', b64.substr(b64.indexOf(',') + 1));
                                                props.setFieldValue('imageType', b64.substring(b64.indexOf(':') + 1, b64.indexOf(';')));
                                                load(b64);
                                            });
                                        },
                                        load: (source, load) => {
                                            fetch(source)
                                                .then(res => {
                                                    console.log(res);
                                                    return res.blob();
                                                })
                                                .then(blob => {
                                                    console.log(blob);
                                                    return load(blob);
                                                });
                                        },
                                    }}
                                ></SncFileUpload>
                            </div>
                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-xl-8">
                            <div className="row no-gutters">
                                <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                    <Field
                                        name="name"
                                        required
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Nombre"
                                        component={TextField}
                                    />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                    <Field
                                        name="firstSurname"
                                        required
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Apellido 1"
                                        component={TextField}
                                    />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                    <Field
                                        name="secondSurname"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Apellido 2"
                                        component={TextField}
                                    />
                                </div>
                            </div>
                            <div className="row no-gutters">
                                <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                    <Field
                                        name="phoneNumber"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Teléfono"
                                        component={TextField}
                                    />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                    <Field
                                        name="mobilePhoneNumber"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        label="Móvil"
                                        component={TextField}
                                    />
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                    <Field name="email" type="text" variant="outlined" fullWidth label="Email" component={TextField} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UserInfoForm;
