import React from 'react';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button } from '@material-ui/core';
import { i18n } from '../../../i18n';

import PropTypes from 'prop-types';

const AlertDialog = props => {
    const { title, text, open, close, onAcceptClick } = props;

    return (
        <React.Fragment>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={close} scroll={'paper'} disableBackdropClick>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Typography>{text}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>{i18n.cancel}</Button>
                    <Button onClick={onAcceptClick} autoFocus>
                        {i18n.accept}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default AlertDialog;

AlertDialog.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onAcceptClick: PropTypes.func.isRequired,
};
