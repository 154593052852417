import React, { useState } from 'react';
import { TextField } from 'formik-material-ui';
import { InputAdornment, Zoom, IconButton } from '@material-ui/core';
import { VpnKey, Visibility, VisibilityOff } from '@material-ui/icons';

const InputPassword = props => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);
    return (
        <Zoom in={true}>
            <TextField
                type={showPassword ? 'text' : 'password'}
                {...props}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <VpnKey style={{ color: 'grey' }} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton tabIndex="-1" onClick={togglePassword} onMouseDown={e => e.preventDefault()}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            ></TextField>
        </Zoom>
    );
};

export default InputPassword;
