export const LOAD_ROLES = 'LOAD_ROLES';
export const ROLES_LOADING = 'ROLES_LOADING';
export const ROLES_LOAD_FAILED = 'ROLES_LOAD_FAILED';
export const LOAD_USER_ROLES_GROUP = 'LOAD_USER_ROLES_GROUP';
export const UPDATE_NEW_ROLE_NAME = 'UPDATE_NEW_ROLE_NAME';
export const UPDATE_NEW_ROLE_DESCRIPTION = 'UPDATE_NEW_ROLE_DESCRIPTION';
export const SET_CHECKED_ROLES = 'SET_CHECKED_ROLES';
export const SET_ASSIGNED_ROLES = 'SET_ASSIGNED_ROLES';

export const ROLES_UPDATING = 'ROLES_UPDATING';
export const ROLES_UPDATING_FAILED = 'ROLES_UPDATING_FAILED';
export const ROLES_UPDATED = 'ROLES_UPDATED';

export const ROLES_CREATING = 'ROLES_CREATING';
export const ROLES_CREATING_FAILED = 'ROLES_CREATING_FAILED';
export const ROLES_CREATED = 'ROLES_CREATED';
