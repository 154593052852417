import React from 'react';
import { useSelector, connect } from 'react-redux';
import { getModulePermissions, getPermissions } from './permission-utils';

export const PermissionContext = React.createContext(null);
export const PermissionConsumer = PermissionContext.Consumer;

const mapStateToProps = state => {
    return {
        config: state.config.config,
    };
};

const ModulePermissionProvider = props => {
    const modules = useSelector(state => state.apps.modules);
    const users = useSelector(state => state.users);
    const { roles } = useSelector(state => state.roles);
    const { permissions } = useSelector(state => state.permissions);

    const modulePermissions = getModulePermissions(users.loggedUser.groups, props.module, modules, roles, permissions, props.config.APP.ID);
    const effectivePermissions = getPermissions(props.module, modulePermissions);

    return <PermissionContext.Provider value={effectivePermissions}>{props.children}</PermissionContext.Provider>;
};

export default connect(mapStateToProps, null)(ModulePermissionProvider);
